import {Injectable} from '@angular/core';
import {config} from '../config';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private RESTAURANT_ID: any;
  private token: any;


  constructor(private http: HttpClient) {
    this.RESTAURANT_ID = sessionStorage.getItem('restaurant_id');
    this.token = sessionStorage.getItem('token');
  }


  getRestaurantOrders(when) {
    const url = config.API_URL + 'v1/admin/orders/';
    return this.http.get(url + this.RESTAURANT_ID + '/' + when);
  }

  getTransactionOrders(when) {
    const url = config.API_URL + 'v1/admin/dashboard/';
    return this.http.get(url + this.RESTAURANT_ID + '/' + when);
  }

  getAllOrders(data) {
    this.token = sessionStorage.getItem('token');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/getAllOrders';
    return this.http.post(url, data, {headers: headers});
  }

  getActivityByOrderId(orderId: any) {
    this.token = sessionStorage.getItem('token');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/admin/getActivityByOrderId?_id=' + orderId;
    return this.http.get(url, {headers: headers});
  }


  updateOrderStatus(orderId, order_status) {
    const url = config.API_URL + 'v1/admin/updateOrderStatus';
    const body = {_id: orderId, order_status: order_status};
  }
}
