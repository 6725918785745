import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import {config} from '../config';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private RESTAURANT_ID;
  private token: any;

  constructor(private http: HttpClient) {
    this.RESTAURANT_ID = sessionStorage.getItem('restaurant_id');
    this.token = sessionStorage.getItem('token');
  }

  getMenu() {
    const url = config.API_URL + 'v1/admin/menu/';
    return this.http.get<any>(url + this.RESTAURANT_ID);
  }

  getGroupDish() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/getGroupDish/';
    return this.http.get<any>(url + this.RESTAURANT_ID, {headers: headers});
  }

  disableCuisine(data) {
    data.restaurant_id = this.RESTAURANT_ID;
    const url = config.API_URL + 'v1/admin/disableCuisine';
    return this.http.put(url, data);
  }

  deleteCuisine(cid) {
    const data = {restaurant_id: this.RESTAURANT_ID, cuisine_id: cid};
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const reqx = new HttpRequest('DELETE', config.API_URL + 'v1/admin/deleteCuisine', data, {
      reportProgress: true,
      headers: headers
    });
    return this.http.request(reqx);
  }

  createCuisine(data) {
    const d = {
      data: data,
      restaurant_id: this.RESTAURANT_ID
    }
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const reqx = new HttpRequest('POST', config.API_URL + 'v1/admin/createCuisine', d, {
      reportProgress: true,
      headers: headers
    });
    return this.http.request(reqx);
  }

  /*Category*/
  createCategory(formData) {
    this.RESTAURANT_ID = localStorage.getItem('restaurant_id');
    const cuisine_id = formData.cuisine_id;
    delete formData.cuisine_id;
    const data = {restaurant_id: this.RESTAURANT_ID, cuisine_id: cuisine_id, data: formData};
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const reqx = new HttpRequest('PUT', config.API_URL + 'v1/admin/createCategory', data, {
      reportProgress: true,
      headers: headers
    });
    return this.http.request(reqx);
  }

  updateCategory(formData, category_id) {
    this.RESTAURANT_ID = localStorage.getItem('restaurant_id');
    const cuisine_id = formData.cuisine_id;
    delete formData.cuisine_id;
    const data = {restaurant_id: this.RESTAURANT_ID, cuisine_id: cuisine_id, category_id: category_id, data: formData};
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const reqx = new HttpRequest('PUT', config.API_URL + 'v1/admin/editCategory', data, {
      reportProgress: true,
      headers: headers
    });
    return this.http.request(reqx);
  }


  deleteCategory(cid, category_id) {
    this.RESTAURANT_ID = localStorage.getItem('restaurant_id');
    const data = {restaurant_id: this.RESTAURANT_ID, cuisine_id: cid, category_id: category_id};
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const reqx = new HttpRequest('DELETE', config.API_URL + 'v1/admin/deleteCategory', data, {
      reportProgress: true,
      headers: headers
    });
    return this.http.request(reqx);
  }

  disableCategory(data) {
    this.RESTAURANT_ID = localStorage.getItem('restaurant_id');
    data.restaurant_id = this.RESTAURANT_ID;
    const url = config.API_URL + 'v1/admin/disableCategory';
    return this.http.put(url, data);
  }

  createDish(cindex, cuisineId, cat_id, item) {
    this.RESTAURANT_ID = localStorage.getItem('restaurant_id');
    const data = {
      restaurant_id: this.RESTAURANT_ID,
      cuisine_index: cindex,
      cuisine_id: cuisineId,
      category_id: cat_id,
      data: item
    };

    const url = config.API_URL + 'v1/admin/createDish';
    return this.http.put(url, data);
  }

  createGroupDish(data) {
    this.RESTAURANT_ID = localStorage.getItem('restaurant_id');
    data.restaurant_id = this.RESTAURANT_ID;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);

    const url = config.API_URL + 'v1/auth/admin/addGroup';
    return this.http.post(url, data, {headers: headers});
  }

  updateGroupDish(data) {
    data.restaurant_id = this.RESTAURANT_ID;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);

    const url = config.API_URL + 'v1/auth/admin/updateGroupDish';
    return this.http.put(url, data, {headers: headers});
  }

  removeGroupDish(cid) {
    const data = {_id: cid};
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const reqx = new HttpRequest('DELETE', config.API_URL + 'v1/auth/admin/deleteGroupDish', data, {
      reportProgress: true,
      headers: headers
    });
    return this.http.request(reqx);
  }

  editDish(cindex, cuisineId, cat_id, dish_id, item) {
    const data = {
      restaurant_id: this.RESTAURANT_ID,
      dish_id: dish_id,
      cuisine_index: cindex,
      cuisine_id: cuisineId,
      category_id: cat_id,
      data: item
    };
    const url = config.API_URL + 'v1/admin/editDish';
    return this.http.put(url, data);
  }

  deleteDish(data) {
    data.restaurant_id = this.RESTAURANT_ID;
    const url = config.API_URL + 'v1/admin/deleteDish';
    return this.http.put<any>(url, data);
  }


  duplicateMenu(data) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/admin/duplicateRestaurantMenu';
    return this.http.post(url, data, {headers: headers});
  }


  getCourse() {
    let params = new HttpParams();
    params =  params.set('restaurant_id', this.RESTAURANT_ID);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/menu/getCourses';
    return this.http.get<any>(url, {params: params, headers: headers});
  }

  createCourse(data) {
    data.restaurant_id = this.RESTAURANT_ID;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/menu/createCourse';
    return this.http.post(url, data, {headers: headers});
  }

  updateCourse(data) {
    data.restaurant_id = this.RESTAURANT_ID;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/menu/updateCourse';
    return this.http.put(url, data, {headers: headers});
  }

  deleteCourse(id) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/menu/removeCourse?_id='+id;
    return this.http.delete(url, {headers: headers});
  }



}
