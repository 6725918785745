import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {config} from '../config';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private RESTAURANT_ID;
  private token: any;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'my-auth-token'
    })
  };

  constructor(private http: HttpClient) {
    this.RESTAURANT_ID = sessionStorage.getItem('restaurant_id');
    this.token = sessionStorage.getItem('token');
  }

  public getDeliveryArea() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/admin/deliveryArea/' + this.RESTAURANT_ID;
    return this.http.get(url, {headers: headers});
  }

  public saveDeliveryArea(data) {
    this.RESTAURANT_ID = localStorage.getItem('restaurant_id');
    data.restaurant_id = this.RESTAURANT_ID;
    const url = config.API_URL + 'v1/admin/saveDeliveryArea';
    return this.http.post<any>(url, data);
  }

  public updateDeliveryArea(data) {
    this.RESTAURANT_ID = localStorage.getItem('restaurant_id');
    const url = config.API_URL + 'v1/admin/updateDeliveryAreas';
    return this.http.put<any>(url, data);
  }

  public removeArea(rid) {
    this.RESTAURANT_ID = localStorage.getItem('restaurant_id');
    const data = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        _id: rid,
        restaurant_id: this.RESTAURANT_ID
      }
    };


    const url = config.API_URL + 'v1/admin/removeArea';
    return this.http.delete(url, data);
  }

  public createPosPrinter(data) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    this.RESTAURANT_ID = localStorage.getItem('restaurant_id');
    data.restaurant_id = this.RESTAURANT_ID;
    const url = config.API_URL + 'v1/auth/admin/createPosPrinter';
    return this.http.post<any>(url, data, {headers: headers});
  }

  findPosPrinters() {
    this.RESTAURANT_ID = localStorage.getItem('restaurant_id');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/findPosPrinters?restaurant_id=' + this.RESTAURANT_ID;
    return this.http.get(url, {headers: headers});
  }

  public updatePosPrinter(data, id) {
    this.RESTAURANT_ID = localStorage.getItem('restaurant_id');
    data.restaurant_id = this.RESTAURANT_ID;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/updatePosPrinter?id='+id;
    return this.http.put<any>(url, data, {headers: headers});
  }

  deletePosPrinter(id) {
    console.log(id)
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/deletePosPrinter?id='+id;
    return this.http.delete(url, {headers: headers});
  }
}
