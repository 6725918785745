import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {config} from '../config';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {
  private RESTAURANT_ID;

  constructor(private http: HttpClient) {
    this.RESTAURANT_ID = sessionStorage.getItem('restaurant_id');
  }

  getReservations(when, status) {
    const url = config.API_URL + 'v1/admin/booking/';
    return this.http.get(url + this.RESTAURANT_ID + '/' + status + '/' + when);
  }

  getReservationsSum(when) {
    const url = config.API_URL + 'v1/admin/bookingSummary/';
    return this.http.get(url + this.RESTAURANT_ID + '/' + when);
  }

  updateBookingStatus(bookingId, status) {
    const url = config.API_URL + 'v1/admin/updateBookingStatus';
    const body = {_id: bookingId, status: status};
    return this.http.put(url, body);
  }
}
