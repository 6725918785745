import {Injectable} from '@angular/core';
import {config} from '../config';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  private RESTAURANT_ID;
  private token: any;

  constructor(private http: HttpClient) {
    this.RESTAURANT_ID = sessionStorage.getItem('restaurant_id');
    this.token = sessionStorage.getItem('token');
  }

  getCMSData() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v2/auth/admin/getCmsData/' + this.RESTAURANT_ID;
    return this.http.get(url, {headers: headers});
  }

  updateData(item) {
    const data = {restaurant_id: this.RESTAURANT_ID, data: item};
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v2/auth/admin/updateCmsData';
    //return this.http.put(url, data, {headers: headers});
    const reqx = new HttpRequest('PUT', url, data, {reportProgress: true, headers: headers});
    return this.http.request(reqx);
  }

  addEventData(item) {
    item.restaurant_id = this.RESTAURANT_ID;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/admin/addEvent';
    return this.http.post(url, item, {headers: headers});
  }


  addCssCode(item, hostName) {
    const data = {restaurant_id: this.RESTAURANT_ID, data: item};
    const url = config.API_URL + 'v1/admin/createCSSFile?hostname=' + hostName;
    return this.http.post<any>(url, data);
  }

  
  getEventData() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/admin/getEventData/' + this.RESTAURANT_ID;
    return this.http.get<any>(url, {headers: headers});
  }
  updateEvent(item) {
    const data = {restaurant_id: this.RESTAURANT_ID, data: item};
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/admin/updateEvent';
    const reqx = new HttpRequest('PUT', url, data, {reportProgress: true, headers: headers});
    return this.http.request(reqx);
  }
  removeEvent(id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        _id: id
      }
    }
    const url = config.API_URL + 'v1/admin/deleteEvent';
    return this.http.delete(url, options);
  }

  getBlogData() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/getArticlesListByRestaurant/' + this.RESTAURANT_ID;
    return this.http.get<any>(url, {headers: headers});
  }
  addBlogData(item) {
    item.restaurant_id = this.RESTAURANT_ID;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/createArticle';
    return this.http.post(url, item, {headers: headers});
  }  
  // updateBlog(item) {
  //   const data = {restaurant_id: this.RESTAURANT_ID, data: item};
  //   console.log(data)
  //   let headers = new HttpHeaders().set('Content-Type', 'application/json');
  //   headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
  //   const url = config.API_URL + 'v1/auth/admin/updateArticle';
  //   const reqx = new HttpRequest('PUT', url, data, {reportProgress: true, headers: headers});
  //   return this.http.request(reqx);
  // }

  updateBlog(item) {
    // const data = {restaurant_id: this.RESTAURANT_ID, data: item};
    // console.log(data)
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/updateArticle';
    return this.http.put(url, item, {headers: headers});
  }

  removeBlog(id) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Access-Token', 'Bearer ' + this.token);
    const url = config.API_URL + 'v1/auth/admin/removeArticle/'+ id;
    return this.http.delete<any>(url, {headers: headers});

  }

  


}
